<template>
  <v-app style="height: 100vh">
    <v-app-bar
      app
      color="light"
      outlined
      flat
      clipped-left
    >
      <v-img src="..\..\assets\prelude-logo.png" max-width="120"></v-img>
      <v-spacer></v-spacer>
      <v-btn @click="logoutReload" icon fab><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>
    <v-main v-if="!unauthorizedUser" style="height: 100%">
      <snack-bar />
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import SnackBar from '../../components/SnackBar.vue'
import { auth, api } from '../../sharedPlugin'
import { logoutHelper } from '../../utils/logout'
import Storage from '../../utils/storage'

export default {
  components: { SnackBar },
  data: () => ({
    unauthorizedUser: false
  }),
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    ...auth.mapMethods(['logout']),
    logoutReload() {
      logoutHelper(this.logout)
    },
    async checkVersions(){
      const currentVersion = await api.Versions.latestChanges()
      try{
        const storedVersionId = await Storage.get("versionId")
        if(storedVersionId != currentVersion.id){
          this.changes = currentVersion.notes
          this.dialog = true
          Storage.set("versionId", currentVersion.id)
        }
      } catch (error) {
        Storage.set("versionId", currentVersion.id)
        console.error(error)
      }
    }
  },
  created() {
    if(this.user.role === "patient") {
      this.unauthorizedUser = true
      this.$router.push({path: '/'}).catch(() => null)
    } else {
      this.checkVersions()
    }
  }
}
</script>

<style scoped>
  .theme--light.v-sheet {
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 1px 1px 1px 0;
  }
</style>