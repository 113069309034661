import { api } from "../sharedPlugin";
import storage from "./storage";

export const compareAndStoreTokens = (currentToken) => storage.get('fcmToken')
  .catch(() => '')
  .then(saved => {
    if(saved !== currentToken){
      return api.Fcm.addToken({token: currentToken})
        .finally(() => storage.set('fcmToken', currentToken))
    }
  });

export const MessagingStatus = {
  UNAVAILABLE: {
    success: false,
    retryable: false,
    message: 'This browser does not support desktop notifications. Please use a different browser or the download prelude connect app'
  },
  DENIED: {
    success: false,
    retryable: true,
    message: 'Messaging requires notification permissions'
  },
  UNKNOWN_ERROR: (msg) => ({
    success: false,
    retryable: true,
    message: `Unknown error initializing messages: ${msg}`
  }),
  GRANTED: {
    success: true
  },
}

export const getNotificationPermissions = (silent) => new Promise((resolve, reject) => {
  if(!("Notification" in window)){
    return reject(MessagingStatus.UNAVAILABLE);
  }
  if(Notification.permission === 'granted'){
    return resolve()
  }
  if(silent){
    return reject(MessagingStatus.DENIED);
  }
  return Notification.requestPermission()
    .then(perm => {
      if(perm === 'granted'){
        resolve()
      }else{
        reject(MessagingStatus.DENIED)
      }
    })
    .catch(e => reject(MessagingStatus.UNKNOWN_ERROR(e)));
})