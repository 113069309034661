import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import EhrUserLayout from '../views/layouts/EhrUserLayout.vue';
import axios from 'axios'

Vue.use(VueRouter)

export const authorizedRoutes = [

  {
    path: '/',
    name: 'Cryo',
    component: () => import('../views/Cryo2.vue'),
    meta: {
      icon: 'mdi-snowflake'
    }
  },
]

export const unauthorizedRoutes = [
  {
    path: '',
    name: 'Checking',
    component: () => import('../views/Auth/CheckingAuth.vue')
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('../views/Auth/LoginSignUp.vue')
  }
]

const authorizedEmployeeRoutes = [
  {
    path: '/messages/',
    component: () => import('../views/Employee.vue'),
  },
  {
    path: '/messages/:conversationId',
    component: () => import('../views/Employee.vue')
  }
]

const routes = [
  {
    path: '/auth',
    component: () => import('../views/Auth/AuthContainer.vue'),
    children: unauthorizedRoutes
  },
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    children: authorizedRoutes
  },
  {
    path: '/messages',
    component: EhrUserLayout,
    children: authorizedEmployeeRoutes
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.length <= 0) {
    next({ path: '/'})
  } else if(!to.fullPath.startsWith('/auth') && !store.getters['auth/loggedIn']){
    next({path: '/auth', query: {redirect: to.path}});
  } else {
    next();
  }
})

export default router
