import {SharedPlugin} from '@inception/connect-shared';
import store from './store';
import Storage from './utils/storage.js';

const {auth, messages, calendar, api} = new SharedPlugin({ 
  store: store,
  localStorage: Storage,
  isProduction: process.env.NODE_ENV === 'production',
  authLoggedInCallBack: () => null,
  messageShownCallback: () => null,
  conversationSelected: () => null
});

export {auth, messages, api, calendar};