<template>
  <router-view/>
</template>
<script>
import { auth } from "./sharedPlugin"
import { mapActions } from 'vuex';
export default {
  computed: {
    ...auth.mapComputed(['loggedIn']),
  },
  methods: {
    ...mapActions(['initializeMessaging'])
  },
  mounted(){
    var url = window.location.href
    if(url.includes("havencryo")){
      this.$vuetify.theme.themes.light.primary = '#888c8f'
      this.$vuetify.theme.themes.light.secondary = '#85a5c4'
    }
    if(url.includes("localhost")){
      this.$vuetify.theme.themes.light.primary = '#888c8f'
      this.$vuetify.theme.themes.light.secondary = '#85a5c4'
    }
  },
  watch: {
    loggedIn(val) {
      //if user is logged in set up firebase
      if(val > 0) {
        this.initializeMessaging();
      }
    }
  }
}
</script>