<template>
  <v-snackbar v-model="isShowSnackbar" :color="color" top>
    {{ message }}
    <v-btn dark text @click="isShowSnackbar = false">Close</v-btn>
  </v-snackbar>
</template>
<script>
export default {
  computed: {
    isShowSnackbar: {
      get(){
        return this.$store.state.isShowSnackbar;
      },
      set(val){
        if(!val){
          this.$store.commit('hideSnackbar');
        }
      }
    },
    color(){
      return this.$store.state.color;
    },
    message(){
      return this.$store.state.message;
    }
  }
}
</script>