import Vue from 'vue'
import Vuex from 'vuex'
import fireBase from '../utils/fireBase';
import { compareAndStoreTokens, getNotificationPermissions, MessagingStatus } from '../utils/messaging';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isShowSnackbar: false,
    message: '',
    color: '',
    messagingStatus: false,
  },
  getters: {
    messagesEnabled: (state) => {
      state.messagingStatus.success
    }
  },
  mutations: {
    showSnackbar(state, {message, color}) {
      console.log("running", message)
      state.isShowSnackbar = true;
      state.message = message;
      state.color = color;
    },
    hideSnackbar(state) {
      state.isShowSnackbar = false;
      state.message = '';
    },
    setMessagingStatus(state, status){
      if(status.success !== undefined){
        state.messagingStatus = status;
      }else{
        state.messagingStatus = MessagingStatus.UNKNOWN_ERROR(status);
      }
    }
  },
  actions: {
    initializeMessaging({commit, state, dispatch}){
      getNotificationPermissions(state.messagingStatus === false)
        .then(() => fireBase.messaging().getToken({vapidKey: process.env.VUE_APP_FIREBASE_VAPIDKEY}))
        .then(compareAndStoreTokens)
        .then(() => {
          navigator.serviceWorker.addEventListener('message', event => {
            const { data } = event.data;
            console.log('FIREBASE DATA: ',data);
            if(data.type === 'message') {
              // use when testing from firebase console
              //const msg = JSON.parse(data.message);
              // const msg = data.message;
              if(state.messages.selectedConversation == +data.conversationId){
                dispatch('messages/pullMessagesAsync', +data.conversationId)
              } else {
                dispatch('messages/getConversationsAsync')
                //.then(() => dispatch('messages/selectConversationAsync', +data.conversationId))
              }
            } else {
              // update calendar data
              dispatch('calendar/pullAppointmentsAsync')
              dispatch('calendar/getMedsAsync')
            }
          });
          commit('setMessagingStatus', MessagingStatus.GRANTED);
        })
        .catch(e => commit('setMessagingStatus',e))
    }
  },
  modules: {
  }
})

export default store;
