import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#305676",
        secondary: "#253035",
        accent: "#ffffff"
      },
      light: {
        primary: "#305676",
        secondary: "#253035",
        accent: "#ffffff"
      },
      haven: {
        primary: "#85a5c4",
        secondary: "#888c8f",
        accent: "#fffffff"
      }
    },
    options: {
      customProperties: true
    }
  }
});
